import React from 'react';

export function RestrictionsButtonPlaceholder() {
	// The RestrictionsDialog will be positioned based on the initial dimensions of
	// RestrictionsButton, so we need to render a placeholder instead of `null`
	// This placeholder is also used in SSR.
	// All the place that is waiting for data in restrictions package should use this placeholder instead of null.
	return (
		<div
			data-testid="restrictions-button-placeholder"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={{ width: 32, height: 32 }}
		/>
	);
}
